@import "./global.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

.alert-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.alert-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.alert-content svg {
    margin-bottom: 16px;
}

.alert-content h2 {
    color: #333;
    margin-bottom: 8px;
}

.alert-content p {
    color: #6b7280;
    margin-bottom: 24px;
}

.btn-alert {
    background-color: #1e70da;
    border: none;
    color: white;
    padding: 10px 20px;
    width: 100px;
    border-radius: 24px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-alert:hover {
    background-color: #5c98e7;
}